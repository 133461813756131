<template>
    <div class="container">
         <Pbanner v-if="contactData.catinfo" :pbanner="contactData.catinfo.thumb"/>
         <div class="leval-menu">
            <div class="leval-menu-wrap inner">
            <Levalmenu :menuNumber="menuNumber"/>
            <div class="posites">
                <span>所在位置：</span>
                <router-link to="/home">首页</router-link>
                <b>></b>
                <router-link to="/contact/contact">联系我们</router-link>
                <b>></b>
                <router-link to="/contact/message">在线留言</router-link>
            </div>
            </div>  
        </div>
        <div class="message-box">
            <div class="culture-title" >
                <small>Online message</small>
                <strong>在线<b>留言</b></strong>
            </div>
            <div class="messages">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                    <div class="row">
                        <el-form-item prop="username">
                            <el-input placeholder="姓名:" v-model="ruleForm.username"></el-input>
                        </el-form-item>
                            <el-form-item prop="telphone">
                        <el-input placeholder="电话:" v-model="ruleForm.telphone"></el-input>
                        </el-form-item>
                        <el-form-item prop="email">
                            <el-input placeholder="邮箱:" v-model="ruleForm.email"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item prop="content">
                        <el-input class="textarea" type="textarea" placeholder="留言:" v-model="ruleForm.content"></el-input>
                    </el-form-item>
                    <div class="message-btn">
                        <el-form-item class="message-sub">
                            <el-button @click="submitForm('ruleForm')">提  交</el-button>
                            <el-button @click="resetForm('ruleForm')">重  置</el-button>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import Pbanner from '../../components/Pbanner';
import Levalmenu from '../../components/Levalmenu';
export default {
    data(){
        return {
            menuNumber: 5,
            contactData: [],
            numble: 0,
            ruleForm: {
                username: '',
                telphone:'',
                email: '',
                content: '',
            },
            rules: {
                username: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                    { min: 2, max: 6, message: '长度在 2 到 6 个字符', trigger: 'blur' }
                ],
                telphone: [
                    { required: true, message: '请输入电话', trigger: 'blur' },
                    { pattern:/^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/,message: '请输入正确的电话', trigger: "blur" }
                ],
                email: [
                    { required: true, message: '请输入邮箱地址', trigger: 'blur' },
                    { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                ],
                content: [
                    { required: true, message: '请填写留言', trigger: 'blur' }
                ]
            }
        }
    },
    components: {
        Pbanner,
        Levalmenu,
    },
    methods: {
        async getContact(){
            const {data: res} = await this.$http.post("getContact");
            if(res.status == 1){
                this.contactData = res.data;
            }else{
                this.$message.error('数据请求失败');
            }
        },
        changeImgCode(){
            this.numble -= 1;
            var num=Math.ceil(Math.random()*10);
            this.imgCode = `${this.imgCode}?${num}`;
            document.querySelector(".replace").style.transform = "rotate("+(-90*this.numble)+"deg)";
        },
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                var formData=new FormData();
                formData.append('username',this.ruleForm.username);
                formData.append('telphone',this.ruleForm.telphone);
                formData.append('email',this.ruleForm.email);
                formData.append('content',this.ruleForm.content);
                
                if (valid) {
                     const {data: res} = await this.$http.post("message_add", formData);
                    if(res.status == 1){
                        this.$message({
                            message: '恭喜你，提交成功',
                            type: 'success'
                        });
                    }else{
                        this.$message.error(res.info);
                    }
                } else {
                    this.$message.error('提交失败');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    },
    mounted() {
        this.getContact();
        
    }
}
</script>

<style lang="less">
    .message-box{
        padding: 70px 10.41% 140px;
        background: url(../../assets/messagebg.png) center bottom no-repeat;
        background-size: 100% auto;
    }
    .messages{
        margin-top: 44px;
        padding: 86px 5.26% 96px;
        box-shadow: 0 0 8px rgba(0,0,0,0.1);
    }
    .row{
        display: flex;
        justify-content: space-between;
        .el-form-item{
            width: 31.83%;
            .el-input__inner{
                height: 50px;
                border-color: #cccccc;
                line-height: 50px;
                border-radius: 5px;
                font-size: 16px;
                &::-webkit-input-placeholder{
                    color: #333333;
                }
                &:focus{
                    border-color: #F56C6C;
                }
            }
        }
    }
    .messages .el-form-item{
        margin-bottom: 35px;
    }
    .textarea .el-textarea__inner{
        padding: 10px 15px;
        height: 238px;
        line-height: 1.8 !important;
        color: #333333;
        font-size: 16px;
        border-radius: 5px;
        font-family: "微软雅黑";
        border-color: #cccccc;
        &::-webkit-input-placeholder{
            color: #333333;
        }
        &:focus{
            border-color: #F56C6C;
        }
    }
    .message-btn{
        display: flex;
        justify-content: space-between;
    }
    .verificate{
        display: flex;
        
    }
    .verificate{
        display: flex;
        align-items: center;
        .el-form-item{
            margin-bottom: 0;
        }
        .el-form-item__content{
            display: flex;
            width: 400px;
            .el-input{
                width: 100%;
                flex: 1;
                .el-input__inner{
                    border-radius: 5px 0 0 5px;
                    height: 50px;
                    line-height: 50px;
                    border-color: #cccccc;
                    font-size: 16px;
                    color: #333333;
                    &::-webkit-input-placeholder{
                        color: #333;
                    }
                    &:focus{
                        border-color: #F56C6C;
                    }
                }
            }
        }
        img{
            display: block;
            height: 50px;
            width: auto;
        }
        .replace{
            display: block;
            margin-left: 14px;
            width: 49px;
            height: 43px;
            background: url(../../assets/replace.png) center no-repeat;
            background-size: cover;
            transition: 0.38s;
        }
    }
    .message-sub{
        margin-bottom: 0 !important;
        display: flex;
        .el-button{
            margin-left: 40px !important;
            padding: 0;
            border: none;
            border-radius: 0;
            &:first-child{
                margin-left: 0 !important;
                span{
                    background: #c4261d;
                }
            }
            span{
                display: block;
                width: 200px;
                height: 58px;
                line-height: 58px;
                border-radius: 5px;
                text-align: center;
                color: #ffffff;
                font-size: 24px;
                transition: 0.5s;
                background: #828282;
                &:hover{
                    background: #c4261d;
                }
            }
        }
    }
    .culture-title{
    text-align: center;
    small{
      display: block;
      margin-bottom: 16px;
      text-align: center;
      line-height: 1;
      color: #d9d9d9;
      font-size: 34px;
      text-transform: uppercase;
    }
    strong{
      display: inline-block;
      position: relative;
      padding: 0 68px;
      line-height: 1;
      color: #000000;
      font-size: 34px;
      &:before,&:after{
        position: absolute;
        top: 50%;
        content: "";
        width: 30px;
        height: 1px;
        background: #030303;
      }
      &:before{
        left: 0;
      }
      &:after{
        right: 0;
      }
      b{
        color: #c4261d;
      }
    }
  }
</style>