<template>
    <div class="container">
         <Pbanner v-if="contactData.catinfo" :pbanner="contactData.catinfo.thumb"/>
         <div class="leval-menu">
            <div class="leval-menu-wrap inner">
            <Levalmenu :menuNumber="menuNumber"/>
            <div class="posites">
                <span>所在位置：</span>
                <router-link to="/home">首页</router-link>
                <b>></b>
                <router-link to="/contact/contact">联系我们</router-link>
                <b>></b>
                <router-link to="/contact/contact">联系方式</router-link>
            </div>
            </div>  
        </div>
        <div class="contact-box">
            <div class="culture-title" v-if="contactData.catinfo">
                <small>{{contactData.catinfo.ename}}</small>
                <strong>联系<b>方式</b></strong>
            </div>
            <div class="contact-down">
                <div class="contact-left">
                    <ul class="contact-list" v-if="contactData.list">
                        <li v-for="(item,index) in contactData.list" :key="item.cid" @click="setPoint(item.long, item.lat)">
                            <div class="contact-tops">
                                <div class="contact-tops_wrap">
                                    <b v-if="index+1 <= 9">{{'0'+(index+1)}}</b>
                                    <b v-else>{{index+1}}</b>
                                    <span>{{item.title}}</span>
                                    <i class="iconfont">&#xe607;</i>
                                </div>
                            </div>
                            <div class="contact-address">
                                <strong>{{item.title}}</strong>
                                <p><i class="iconfont">&#xe656;</i>{{item.address}}</p>
                                <p><i class="iconfont tel">&#xe631;</i>{{item.tel}}</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="map" id="map">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pbanner from '../../components/Pbanner';
import Levalmenu from '../../components/Levalmenu';
import mapsicon from '../../assets/map1.png'
export default {
    data(){
        return {
            menuNumber: 5,
            contactData: [],
            zoom: 13,
            long:"",
            lat:"",
            center: [109.45744048529967, 36.49771311230842],
        }
    },
    components: {
        Pbanner,
        Levalmenu,
    },
    methods: {
        async getContact(){
            const {data: res} = await this.$http.post("getContact");
            if(res.status == 1){
                this.contactData = res.data;
                console.log(this.contactData)
                this.long = res.data.list[0].long;
                this.lat = res.data.list[0].lat;
                this.center[0] = res.data.list[0].long;
                this.center[1] = res.data.list[1].lat;
            }else{
                this.$message.error('数据请求失败');
            }
        },
        loadMapScenario(){
            var myLatlng = new qq.maps.LatLng(this.center[1],this.center[0]);
            //定义工厂模式函数
            var myOptions = {
                zoom: 14,               //设置地图缩放级别
                center: myLatlng,      //设置中心点样式
                mapTypeId: qq.maps.MapTypeId.ROADMAP  //设置地图样式详情参见MapType
            }
            //获取dom元素添加地图信息
            var map = new qq.maps.Map(document.getElementById("map"), myOptions);
            var anchor = new qq.maps.Point(6, 6),
            size = new qq.maps.Size(24, 24),
            origin = new qq.maps.Point(0, 0),
            icon = new qq.maps.MarkerImage('https://mapapi.qq.com/web/lbs/javascriptV2/demo/img/center.gif', size, origin, anchor);
            var marker = new qq.maps.Marker({
            icon: icon,
            map: map,
            position:map.getCenter()});
            //给地图添加点击事件
        },
        toggeleClicks() {
            $(document).on("click", ".contact-tops", function(){
                $(this).stop().slideUp();
                $(this).parents("li").siblings().find(".contact-tops").stop().slideDown();
                $(this).parents("li").find(".contact-address").stop().slideDown();
                $(this).parents("li").siblings().find(".contact-address").stop().slideUp();
            })
        },
        setPoint(long,lat) {
            this.center[0] = long
            this.center[1] = lat
            this.loadMapScenario()
        }
    },
    mounted() {
        this.getContact();
        setTimeout(()=>{
            this.loadMapScenario()
            this.toggeleClicks()
        }, 1200)
    }
}
</script>

<style lang="less">
    .contact-box{
        padding: 70px 0 115px;
    }
    .contact-down{
        padding-left: 10.41%;
        margin-top: 80px;
        display: flex;
        justify-content: space-between;
    }
    .contact-left{
        width: 38.72%;
        height: 726px;
        overflow-y: auto;
    }
    .contact-address{
        padding-top: 18px;
        strong{
            display: block;
            margin-bottom: 28px;
            line-height: 1.2;
            color: #c4261d;
            font-size: 34px;
        }
        p{
            color: #333333;
            font-size: 16px;
            i{
                display: inline-block;
                position: relative;
                top: -1px;
                vertical-align: middle;
                color: #c4261d;
                font-style: normal;
                font-size: 22px;
                width: 36px;
                &.tel{
                    left: -12px;
                }
            }
        }
    }
    .contact-address{
        display: none;
    }
    .contact-list{
        li{
            &:first-child{
                .contact-address{
                    display: block;
                }
                .contact-tops{
                    display: none;
                }
            }
            .contact-tops{
                display: flex;
                position: relative;
            }
        }
    }
    .contact-tops_wrap{
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #e5e5e5;
        height: 90px;
        cursor: pointer;
        b{
            display: block;
            position: relative;
            width: 30px;
            height: 30px;
            background: #c4261d;
            text-align: center;
            line-height: 30px;
            color: #fff;
            font-family: Arial;
            font-size: 16px;
            font-weight: normal;
            &:before{
                position: absolute;
                left: 50%;
                margin-left: -5px;
                top: 100%;
                content: "";
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid #c4261d;
            }
        }
        span{
            display: block;
            margin-left: 30px;
            color: #333333;
            font-size: 18px;
            font-weight: bold;
        }
        i{
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -9px;
            display: inline-block;
            color: #494949;
            font-style: normal;
            font-size: 18px;
        }
    }
    .map{
        position: relative;
        width: 55.81%;
        height: 748px;
    }
    #allmap{
        height: 100%;
    }
    .bm_bottomLeftOverlay{
        display: none !important;
    }
    .culture-title{
    text-align: center;
    small{
      display: block;
      margin-bottom: 16px;
      text-align: center;
      line-height: 1;
      color: #d9d9d9;
      font-size: 34px;
      text-transform: uppercase;
    }
    strong{
      display: inline-block;
      position: relative;
      padding: 0 68px;
      line-height: 1;
      color: #000000;
      font-size: 34px;
      &:before,&:after{
        position: absolute;
        top: 50%;
        content: "";
        width: 30px;
        height: 1px;
        background: #030303;
      }
      &:before{
        left: 0;
      }
      &:after{
        right: 0;
      }
      b{
        color: #c4261d;
      }
    }
  }
</style>